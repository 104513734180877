import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Pusher from "pusher-js";
import toast, { Toaster } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";

import "../fonts.css";

// Hooks
import { useCookie } from "../Hooks/useCookie";

// Utils
import { generateName, plainPersistentMessages } from "../utils";
import NetworkManager from "../NetworkManager";
import {
  EVENT_MESSAGE_SENT,
  EVENT_MESSAGE_REACTION,
  messageMaxLength,
  LANGUAGE_CUSTOMER_KEY,
  DEFAULT_LANGUAGE,
  EVENT_CLEAR_TABLE,
} from "../constants";

// Components
import { BottomModal } from "./Components/BottomModal";
import { HeaderNav } from "./Components/HeaderNav";

// Types
import {
  EScreenMode,
  IHandshakeResponse,
  ICategory,
  IProduct,
  ICartItem,
  IMessage,
  EMessageType,
  IKeyMessage,
  IAccount,
} from "../Interfaces/generalInterfaces";

import { Popular } from "./Components/Popular";
import { FoodList } from "./Components/FoodList";
import { CartViewBottom } from "./Components/CartViewBottom";
import { FoodItemDetail } from "./Components/FoodItemDetail";
import { OrderSummary } from "./Components/OrderSumarry";
import { createClient } from "@supabase/supabase-js";
import { Categories } from "./Components/Categories";
import { Loading } from "./Components/Loading";
import { useCustomerViewAuth } from "../Auth/CustomerViewAuth";
import PrivateChannel from "pusher-js/types/src/core/channels/private_channel";
let localChannel: PrivateChannel;

const supabase = createClient(window._env_.DOMAIN, window._env_.ANON_KEY);
export const MainMenu = (): JSX.Element => {
  const auth = useCustomerViewAuth();
  const navigate = useNavigate();
  const [screenMode, setScreenMode] = useState<EScreenMode>(EScreenMode.BACK);
  const [table, setTable] = useState<IHandshakeResponse>(null!);

  const handleStScreenMode = (screenMode: EScreenMode) => {
    setScreenMode(screenMode);
  };
  const [bottomModal, setBottomModal] = useState<boolean>(false);
  const { restaurantUUID, tableUUID, dynamicCode } = useParams();
  const [homeURL, setHomeURL] = useState<string>(null!);
  const [chatURL, setChatURL] = useState<string>(null!);
  const [tableId, setTableId] = useState<string>(null!);
  const [restaurantId, setRestaurantId] = useState<string>(null!);
  const [clientName, setClientName] = useCookie("clientName", "");
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();

  const [selectedCategories, setSelectedCategories] = useState<
    Array<ICategory>
  >([]);
  const [carts, setCarts] = useState<Array<ICartItem>>([]);
  const [showOrderSummary, setShowOrderSummary] = useState<boolean>(false);
  const [showPopular, setShowPopular] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const account = auth.clientAccount;

  const ACCOUNT_ID = restaurantId;
  const RESOURCE_ID = tableId;

  const fetchMapperIdsAndAuthData = async () => {
    try {
      // Fetch mapper IDs
      const response = await NetworkManager.getMapperId({
        restaurantUUID,
        tableUUID,
      });
      const { restaurantId, tableId } = response;
      setRestaurantId(restaurantId);
      setTableId(tableId);

      // Update auth-related fields
      auth.updateAccountId(restaurantId);
      auth.updateResourceId(tableId);
      auth.updateChannelId(dynamicCode);

      if (restaurantId && tableId) {
        // Fetch account and additional data
        auth.getAccount(restaurantId, tableId);
        auth.getCategories(restaurantId);
        auth.getProducts(restaurantId);
        auth.getProductPopularity(restaurantId);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  useEffect(() => {
    setChatURL(`/chat/${restaurantUUID}/${tableUUID}/${dynamicCode}`);
    setHomeURL(`/home/${restaurantUUID}/${tableUUID}/${dynamicCode}`);
    fetchMapperIdsAndAuthData();
  }, [restaurantUUID, tableUUID, dynamicCode]);

  useEffect(() => {
    initializePusher();

    return () => {
      if (localChannel) localChannel.unbind();
    };
  }, [restaurantId, tableId, dynamicCode]); // Add dependencies for Pusher

  useEffect(() => {
    setSelectedCategories(auth.categories);
    if (auth?.carts) {
      setCarts(auth?.carts);
    }
  }, [auth.categories]);
  const [resData, setResData] = useState(null);
  const initializePusher = async () => {
    if (restaurantId && tableId && dynamicCode) {
      const response = await NetworkManager.clientHandshake(
        tableId,
        dynamicCode,
        restaurantId
      );
      setResData(response?.payload);
      clientHandshake(response);
      if (!restaurantId || !tableId || !response.payload.channelId) {
        return setComponentError("Error in table identifiers");
      }
      const pusher = new Pusher(window._env_.PUSHER_APPKEY, {
        authorizer: (channel) =>
          NetworkManager.pusherAuthorizer(channel, restaurantId, tableId),
        cluster: window._env_.PUSHER_CLUSTER,
      });

      localChannel = pusher.subscribe(
        response.payload.channelId
      ) as PrivateChannel;
    }
  };

  const clientHandshake = async (response: any) => {
    if (!tableId || !dynamicCode || !restaurantId) {
      return setComponentError("Error in table identifiers ");
    }
    console.log("WOOF");
    console.log(response.payload);

    if (!response.success || !response.payload || !response.payload?.tableId) {
      const errorMessage =
        response.message || "Unhandled initializing connection";
      return setComponentError(errorMessage);
    }
    setResData(response?.payload);

    setTable(response.payload);
  };

  const messageTrigger = async (message: string, messageType: EMessageType) => {
    console.log("messageTrigger starting");
    const newMessage: IMessage = {
      id: uuidv4(),
      message: message,
      username: clientName,
      originalMessage: message,
      timestamp: Date.now(),
      messageType: messageType,
    };

    const send = await NetworkManager.sendMessageToBackend(
      tableId,
      newMessage,
      table.channelId,
      EVENT_MESSAGE_SENT
    );
    localChannel.trigger(EVENT_MESSAGE_SENT, { ...newMessage });
  };

  const onSelectedItem = (item) => {
    setSelectedProduct(item);
    setBottomModal(true);
  };
  const handleAddToCart = (cart) => {
    const newCarts = [...carts, cart];
    setCarts(newCarts);
    setBottomModal(false);
    auth.updateCarts(newCarts);
  };
  const onSubmitOrder = () => {
    insetOrder();
  };

  async function insetOrder() {
    const totalPrice = carts.reduce((total, item) => {
      return total + item.product.price * item.value;
    }, 0);

    const discount = () => {
      return 0;
    };
    const tax = () => {
      if (account.tax_rate > 0) {
        return totalPrice * (account.tax_rate / 100);
      } else {
        return 0;
      }
    };
    const priceAfterTax = () => {
      return totalPrice + tax();
    };
    const netTotal = () => {
      if (account.is_inclusive) {
        return totalPrice - tax();
      } else {
        return priceAfterTax();
      }
    };

    setShowLoading(true);
    const { data: order, error } = await supabase
      .from("orders")
      .insert([
        {
          order_date: new Date(),
          sub_total: totalPrice,
          account_id: ACCOUNT_ID,
          status: "pending",
          net_total: netTotal(),
          resource_id: RESOURCE_ID,
          tax_amount: tax(),
        },
      ])
      .select();
    if (error) {
    }
    if (order) {
      const orderItems = [];
      carts.forEach(function (v: ICartItem) {
        orderItems.push({
          order_id: order[0].id,
          product_id: v.product.id,
          quantity: v.value,
          price: v.product.price,
          discount_percentage: null,
          tax_percent: null,
          discount_amount: null,
          sub_total: totalPrice,
          total: totalPrice,
          remark: v.remark,
        });
      });
      const { data: orderItem, error } = await supabase
        .from("order_items")
        .insert(orderItems)
        .select("*, products(id,name)");

      if (orderItem) {
        const myOrder = { ...order[0], orderItems: orderItem };
        const myOrderToString = JSON.stringify(myOrder);
        console.log(myOrder, myOrderToString);
        messageTrigger(myOrderToString, EMessageType.ORDER);
        toast.success("Order sumbmitted", toastConfig(true));
        auth.updateCarts([]);
        setCarts([]);
        setShowLoading(false);
        navigate(chatURL);
      }
    }
  }
  const onItemRemoved = (id: string) => {
    const newCarts = carts.filter((item) => item.id !== id);
    setCarts(newCarts);
    auth.updateCarts(newCarts);
    if (newCarts.length <= 0) {
      setShowOrderSummary(false);
    }
  };
  const toastConfig = (success: boolean) => ({
    icon: "",
    style: {
      borderRadius: "30px",
      background: success ? "#2ECC71" : "#222",
      color: "#fff",
      width: "100%",
      paddingLeft: "10px",
    },
  });
  const handleFilterCategory = (category: ICategory) => {
    if (category.id === -1) {
      setSelectedCategories(auth.categories);
      setShowPopular(true);
    } else if (category.id === 0) {
      setShowPopular(true);
      setSelectedCategories([]);
    } else {
      setSelectedCategories([category]);
      setShowPopular(false);
    }
  };

  return (
    <>
      <Toaster />
      <HeaderNav
        setScreenMode={handleStScreenMode}
        screenMode={screenMode}
        title="Menu"
        backURL={homeURL}
      />
      <Categories
        categories={auth.categories}
        onfilter={(category) => handleFilterCategory(category)}
      />
      <div>
        {showPopular && (
          <Popular
            products={auth.popularProducts}
            onSelectedItem={onSelectedItem}
          />
        )}
        <FoodList
          products={auth.products}
          categories={selectedCategories}
          onSelectedItem={onSelectedItem}
        />
      </div>

      <BottomModal
        closeModal={() => setBottomModal(false)}
        show={bottomModal}
        title=""
      >
        <FoodItemDetail
          onAddToCart={handleAddToCart}
          product={selectedProduct}
        />
      </BottomModal>
      {carts.length > 0 && (
        <CartViewBottom
          carts={carts}
          onViewCart={() => setShowOrderSummary(true)}
        />
      )}
      {showOrderSummary && (
        <OrderSummary
          carts={carts}
          account={account}
          onSubmitOrder={onSubmitOrder}
          onDismiss={() => setShowOrderSummary(false)}
          onItemRemoved={onItemRemoved}
        />
      )}
      {showLoading && <Loading />}
    </>
  );
};

function setComponentError(arg0: string) {
  throw new Error("Function not implemented.");
}
